import React, { useState } from 'react';

function VoiceCheckboxSelector({ onVoiceSelect }) {
    // 初始化状态，用于存储选中的值
    const [selectedVoice, setSelectedVoice] = useState('');

    // 声音列表，带有名称和对应的值
    const voiceList = [
        // { name: '嗲学妹', value: 'diadia_xuemei' },
        // { name: '甜妹子', value: 'female-tianmei' },
        // { name: 'zack', value: 'ab_1728565811' },
        // { name: 'Andrew', value: 'ab_1728616262' },

        { name: '可爱男童', value: 'cute_boy' },
        { name: '卡通猪小琪', value: 'bingjiao_didi' },
        { name: '萌萌女童', value: 'lovely_girl' },
        { name: '女性主持人', value: 'presenter_female' },
        { name: 'zack', value: 'ab_1728565811' },
    ];

    // 处理复选框的改变事件
    const handleCheckboxChange = (event) => {
        const newValue = event.target.value;
        // 只允许选中一个
        setSelectedVoice(newValue);
        // 调用父组件传递下来的 onVoiceSelect 函数，传递选中的值
        onVoiceSelect(newValue);
    };

    return (
        <div>
            <h2>Select a Voice</h2>
            <div style={{display: 'flex', flexWrap: 'wrap'}}>

                {voiceList.map((voice, index) => (
                    <div key={index} style={{marginRight: '15px'}}>
                        <input
                            type="checkbox"
                            id={voice.value}
                            name="voice"
                            value={voice.value}
                            checked={selectedVoice === voice.value}
                            onChange={handleCheckboxChange}
                        />
                        <label htmlFor={voice.value}>{voice.name}</label>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default VoiceCheckboxSelector;
