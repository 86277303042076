import React, { useState, useRef, useEffect } from 'react';
import VoiceCheckboxSelector from "../small_components/voice_chooser_comp";
import RoleSelector from "../small_components/role_chooser_comp";

function AudioChat({ uid, businessType, audioLabel }) {
    const [isRecording, setIsRecording] = useState(false);
    const [audioUrl, setAudioUrl] = useState(''); // 用于播放用户录制的音频
    const [playAudioUrl, setPlayAudioUrl] = useState(''); // 用于播放从 WebSocket 返回的音频
    const mediaRecorderRef = useRef(null);
    const audioChunks = useRef([]);
    const socketRef = useRef(null); // 用于存储 WebSocket 实例
    const [selectedVoice, setSelectedVoice] = useState('');
    const [selectedRole, setSelectedRole] = useState('');

    const audioPlayerRef = useRef(null);  // 引用 audio 元素
    const mediaSourceRef = useRef(null);  // MediaSource 对象
    const sourceBufferRef = useRef(null); // SourceBuffer 对象
    const queueRef = useRef([]); // 用于存储未添加的音频块

    // 处理选中值的回调函数
    const handleVoiceSelect = (voice) => {
        setSelectedVoice(voice);
    };
    const handleRoleSelect = (role) => {
        setSelectedRole(role);
    };

    // 初始化 MediaSource 和 SourceBuffer，用于拼接和播放 WebSocket 返回的音频
    useEffect(() => {
        if (!audioPlayerRef.current) return;

        const mediaSource = new MediaSource();
        mediaSourceRef.current = mediaSource;

        mediaSource.addEventListener('sourceopen', () => {
            const sourceBuffer = mediaSource.addSourceBuffer('audio/mpeg'); // 假设音频格式为 MP3
            sourceBufferRef.current = sourceBuffer;

            sourceBuffer.addEventListener('updateend', () => {
                if (queueRef.current.length > 0 && !sourceBuffer.updating) {
                    // 如果有数据块在队列中，继续处理
                    sourceBuffer.appendBuffer(queueRef.current.shift());
                }
            });
        });

        // 将 MediaSource 对象链接到音频元素
        audioPlayerRef.current.src = URL.createObjectURL(mediaSource);
    }, []);

    // 建立 WebSocket 连接
    const openWebSocketConnection = () => {
        const ws = new WebSocket('ws://localhost:8000/ws/chat_audio_file/');
        ws.binaryType = 'arraybuffer'; // 设置 WebSocket 数据类型为二进制
        socketRef.current = ws;

        ws.onopen = () => {
            console.log('WebSocket connection opened.');

            // 发送初始 JSON 数据
            const initData = {
                uid: uid,
                voice_id: selectedVoice,
                receiver_npc_ids: selectedRole // 示例数据
            };
            ws.send(JSON.stringify(initData));
            // setInterval(() => {
            //     ws.send(JSON.stringify({ type: 'ping' }));
            // }, 3000); // 每 3 秒发送一次心跳
        };

        ws.onmessage = (event) => {
            // 如果接收到的是二进制数据（音频）
            if (event.data instanceof ArrayBuffer) {
                if (sourceBufferRef.current && !sourceBufferRef.current.updating) {
                    // 直接将数据添加到 SourceBuffer
                    sourceBufferRef.current.appendBuffer(event.data);
                } else {
                    // 如果 SourceBuffer 正在更新，将数据存入队列
                    queueRef.current.push(event.data);
                }
            } else {
                // 如果接收到的是文本数据
                console.log('Message from server:', event.data);
            }
        };

        ws.onclose = () => {
            console.log('WebSocket connection closed.');
        };

        ws.onerror = (error) => {
            console.error('WebSocket error:', error);
        };
    };

    // 开始录音
    const startRecording = async () => {
        // 检查浏览器是否支持 getUserMedia
        if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
            alert('Your browser does not support audio recording.');
            return;
        }

        // 打开 WebSocket 连接
        openWebSocketConnection();

        // 获取用户的麦克风音频流
        const stream = await navigator.mediaDevices.getUserMedia({ audio: true });

        // 创建 MediaRecorder 实例
        mediaRecorderRef.current = new MediaRecorder(stream);
        mediaRecorderRef.current.ondataavailable = (event) => {
            if (event.data.size > 0) {
                audioChunks.current.push(event.data); // 存储音频数据
            }
        };

        mediaRecorderRef.current.onstop = async () => {
            const audioBlob = new Blob(audioChunks.current, { type: 'audio/wav' });
            const audioUrl = URL.createObjectURL(audioBlob);
            setAudioUrl(audioUrl); // 用于播放录制的音频

            // 在录音停止时上传音频数据到 WebSocket
            if (socketRef.current && socketRef.current.readyState === WebSocket.OPEN) {
                // 发送整个音频文件
                socketRef.current.send(audioBlob);
                console.log('Audio sent to WebSocket');
            }
        };

        // 开始录音
        mediaRecorderRef.current.start();
        setIsRecording(true);
    };

    // 停止录音
    const stopRecording = () => {
        if (mediaRecorderRef.current) {
            mediaRecorderRef.current.stop();
        }
        setIsRecording(false);
    };

    return (
        <div>
            <div>
                <VoiceCheckboxSelector onVoiceSelect={handleVoiceSelect}/>
            </div>

            <div>
                <RoleSelector onRoleSelect={handleRoleSelect}/>
            </div>

            <h3>React Voice Recorder with WebSocket</h3>
            <div>
                <button onClick={startRecording} disabled={isRecording}>
                    {isRecording ? 'Recording...' : 'Start Recording'}
                </button>
                <button onClick={stopRecording} disabled={!isRecording}>
                    Stop Recording
                </button>
            </div>

            {audioUrl && (
                <div>
                    <h2>Recorded Audio:</h2>
                    <audio src={audioUrl} controls/>
                </div>
            )}

            <div>
                <h1>WebSocket Audio Playback</h1>
                <audio ref={audioPlayerRef} controls />
            </div>
        </div>
    );
}

export default AudioChat;
