import React, { useState } from 'react';
import './chatindex.css';  // 引入样式文件
import VoiceRecorder from "../voice_recorder/voice_recorder";
import AudioChat from "../player/audio_player";
// import VoiceRecorder from "../voice_recorder/record_voice";

function ChatPage() {
    // 设置用户UID的状态
    const [uid, setUid] = useState('');
    // 选择业务类型的状态
    const [businessType, setBusinessType] = useState('clone');
    const [audioLabel, setAudioLabel] = useState('');

    // 处理按钮A点击
    const handleButtonAClick = () => {
        alert(`UID: ${uid}, 业务类型: ${businessType} - 按钮A点击`);
    };

    // 处理按钮B点击
    const handleButtonBClick = () => {
        alert(`UID: ${uid}, 业务类型: ${businessType} - 按钮B点击`);
    };

    return (
        <div className="container">
            <h1>Voice Manage</h1>


            <div className="form-group">
                <label htmlFor="uid">User Name</label>
                <input
                    type="text"
                    id="uid"
                    value={uid}
                    onChange={(e) => setUid(e.target.value)}
                    placeholder="what is your name?"
                />
            </div>


            <div className="form-group">
                <label htmlFor="business-type">Trans Type：</label>
                <select
                    id="business-type"
                    value={businessType}
                    onChange={(e) => setBusinessType(e.target.value)}
                >
                    <option value="clone">clone</option>
                    <option value="chat">chat</option>
                </select>
            </div>


            <div className="form-group">
                <label htmlFor="uid">Name Your Voice</label>
                <input
                    type="text"
                    id="audioLabel"
                    value={audioLabel}
                    onChange={(e) => setAudioLabel(e.target.value)}
                    placeholder="Audio Label"
                />
            </div>


            {/*<VoiceRecorder />*/}
            <VoiceRecorder uid={uid} businessType={businessType} audioLabel={audioLabel}/>
            <AudioChat uid={uid} businessType={businessType} audioLabel={audioLabel}>AUDIO_CHAT</AudioChat>


            {/*<div className="button-group">*/}
            {/*    <button className="button" onClick={handleButtonAClick}>按钮 A</button>*/}
            {/*    <button className="button" onClick={handleButtonBClick}>按钮 B</button>*/}
            {/*</div>*/}

            <div>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <span>
                    您可以阅读如下内容:<br/>
                    在当今快节奏的世界里，我们中的许多人总是忙于工作、社交活动和源源不断的信息流。<br/>
                    在这种匆忙中，我们常常忘记了花时间去阅读的重要性。<br/>
                    阅读不仅仅是一种消磨时间的方式，它还是一个提升自我、获取知识和放松身心的强大工具。<br/>
                    首先，阅读让我们可以探索新的世界，无论是真实的还是想象中的。<br/>
                </span>
            </div>
        </div>
    );
}

export default ChatPage;
