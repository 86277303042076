import React, { useState, useRef } from 'react';

function VoiceRecorder({ uid, businessType, audioLabel }) {
    const [isRecording, setIsRecording] = useState(false);
    const [audioUrl, setAudioUrl] = useState('');
    const mediaRecorderRef = useRef(null);
    const audioChunks = useRef([]);




    // 开始录音
    const startRecording = async () => {
        // 检查浏览器是否支持 getUserMedia
        if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
            alert('Your browser does not support audio recording.');
            return;
        }

        // 获取用户的麦克风音频流
        const stream = await navigator.mediaDevices.getUserMedia({ audio: true });



        // 创建 MediaRecorder 实例
        mediaRecorderRef.current = new MediaRecorder(stream);
        mediaRecorderRef.current.ondataavailable = (event) => {
            if (event.data.size > 0) {
                audioChunks.current.push(event.data);
            }
        };

        mediaRecorderRef.current.onstop = () => {
            const audioBlob = new Blob(audioChunks.current, { type: 'audio/wav' });
            const audioUrl = URL.createObjectURL(audioBlob);
            setAudioUrl(audioUrl);
            uploadAudio(audioBlob);
        };

        // 开始录音
        mediaRecorderRef.current.start();
        setIsRecording(true);
    };

    // 停止录音
    const stopRecording = () => {
        if (mediaRecorderRef.current) {
            mediaRecorderRef.current.stop();
        }
        setIsRecording(false);
    };

    // 上传音频文件到后端
    const uploadAudio = async (audioBlob) => {
        const formData = new FormData();
        // const the_filename = uid+`audio_${Date.now()}.wav`;
        // const the_filename = `${uid}_audio_${Date.now()}.wav`;
        const the_filename = `${uid}_audio_${Date.now()}.wav`;
        formData.append('file', audioBlob, the_filename);
        formData.append('uid', uid);  // 用户 UID
        formData.append('audio_name', audioLabel);  // 用户 UID
        formData.append('tran_type', businessType);  // 业务类型

        console.log("formData: ",formData)
        try {
            const response = await fetch('http://localhost:8000/upload_audio', {
                method: 'POST',
                body: formData,
            });

            if (response.ok) {
                alert('Audio uploaded successfully');
            } else {
                alert('Failed to upload audio');
            }
        } catch (error) {
            alert('Error uploading audio');
            console.error(error);
        }
    };

    return (
        <div>
            {/*<h1>React Voice Recorder</h1>*/}
            <div>
                <button onClick={startRecording} disabled={isRecording}>
                    {isRecording ? 'Recording...' : 'Start Clone'}
                </button>
                <button onClick={stopRecording} disabled={!isRecording}>
                    Stop Clone
                </button>
            </div>
            {audioUrl && (
                <div>
                    <h2>Recorded Audio:</h2>
                    <audio src={audioUrl} controls />
                </div>
            )}
        </div>
    );
}

export default VoiceRecorder;
