import React, { useState } from 'react';

function RoleSelector({ onRoleSelect }) {
    // 初始化状态，用于存储选中的多个值
    const [selectedRoles, setSelectedRoles] = useState([]);

    // 声音列表，带有名称和对应的值
    const roleList = [
        { name: 'zack', value: '99kjq5so' },
        { name: '小哪吒', value: '0350a4cd70410f0' },
        // { name: 'Andrew', value: '5tgccLo2' },
        // { name: 'Susan(服务员)', value: '46cq325t' },
        // { name: 'Rachael(大堂经理)', value: '12Bn3isi' },
    ];

    // 处理复选框的改变事件
    const handleCheckboxChange = (event) => {
        const newValue = event.target.value;
        let updatedSelectedRoles = [...selectedRoles];

        // 如果已经选中则取消选中，否则添加到选中项中
        if (updatedSelectedRoles.includes(newValue)) {
            updatedSelectedRoles = updatedSelectedRoles.filter(role => role !== newValue);
        } else {
            updatedSelectedRoles.push(newValue);
        }

        setSelectedRoles(updatedSelectedRoles);
        onRoleSelect(updatedSelectedRoles);
    };

    return (
        <div>
            <h2>Select Roles</h2>
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                {roleList.map((role, index) => (
                    <div key={index} style={{ marginRight: '15px' }}>
                        <input
                            type="checkbox"
                            id={role.value}
                            name="role"
                            value={role.value}
                            checked={selectedRoles.includes(role.value)}
                            onChange={handleCheckboxChange}
                        />
                        <label htmlFor={role.value}>{role.name}</label>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default RoleSelector;
