import logo from './logo.svg';
import './App.css';
import VoiceRecorder from './components/voice_recorder/voice_recorder';
import ChatPage from './components/chat/chat_index';

function App() {
  return (
      <div className="App">
        {/*<VoiceRecorder></VoiceRecorder>*/}
        <ChatPage></ChatPage>
      </div>
  );
}

export default App;
